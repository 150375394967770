<template>
  <div class="title">{{title}}</div>
</template>

<script>
  export default {
    name: 'index',
    data: () => {
      return {
        xx: 22
      }
    },
    props:{
      title:{
        type:String,
        default:''
      }
    },
    components:{
      
    },
    methods: {
      
    }
  }
</script>

<style scoped>
  @font-face{
      font-family: Rajdhani;
      src: url("../../../assets/fonts/YouSheBiaoTiHei-2.ttf");
  }
  .title{
    height: 34px;
    
    font-size: 26px;
    font-family:Rajdhani;
    font-weight: 400;
    text-align: left;
    color: #dd2622;
    line-height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
  .title::before{
    content: "";
    border: 2px #dd2622 solid;
    margin-right: 4px;
    height: 8px;
  }
  .title::after{
    content: "";
    border-bottom: 2px #dd2622 solid;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
</style>