<template>
  <div>
    <div
      class="box1"
      v-show="type == 1">
      <div class="carousel">
        <el-carousel height="210px">
          <el-carousel-item
            v-for="(item, index) in datas.slice(0, 5)"
            :key="index">
            <h3 class="small">
              <img
                :src="item.imgUrl ? item.imgUrl : item.coverUrl"
                style="height: 210px; width: 100%; object-fit: cover" />
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="contents">
        <div
          v-for="(item, index) in datas"
          :key="index"
          @click="toDetail(item)">
          {{ item.name ? item.name : item.title }}
        </div>
      </div>
    </div>
    <div
      class="box1"
      v-show="type == 5">
      <div class="carousel">
        <el-carousel height="210px">
          <el-carousel-item
            v-for="(item, index) in datas.slice(0, 5)"
            :key="index">
            <h3 class="small">
              <img
                :src="item.imgUrl ? item.imgUrl : item.coverUrl"
                style="height: 210px; width: 100%; object-fit: cover" />
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="contents">
        <div
          v-for="(item, index) in datas"
          :key="index"
          @click="toDetail(item)">
          {{ item.name ? item.name : item.title }}
        </div>
      </div>
    </div>
    <div
      class="box2"
      v-show="type == 2">
      <div
        class="content"
        v-for="(item, index) in datas"
        :key="index"
        @click="toDetail(item)">
        <div class="cover">
          <img :src="item.coverUrl" />
        </div>
        <div class="contents">
          <div>
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="box3"
      v-show="type == 3">
      <div class="carousel">
        <el-carousel height="210px">
          <el-carousel-item
            v-for="(item, index) in datas.slice(0, 5)"
            :key="index">
            <h3 class="small">
              <img
                :src="item.coverUrl"
                style="height: 210px; width: 100%; object-fit: cover" />
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="contents">
        <div
          v-for="(item, index) in datas"
          :key="index"
          @click="toDetail(item)">
          <div class="title fs_16">{{ item.title }}</div>
          <div style="opacity: 0.4">{{ item.createTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewList',
  data: () => {
    return {};
  },
  props: {
    datas: [],
    type: {
      type: Number,
      default: 1, //1、志愿服务，走访慰问2、党建动态3、政治生活
    },
    pageId: {
      type: String,
      default: '',
    },
  },
  methods: {
    toDetail(item) {
      if (item.link) return window.open(item.link, '_blank');
      this.$router.push({
        path: '/party/content',
        query: {
          from: this.type == 2 ? 5 : this.type,
          cid: item.id,
          id: this.pageId,
        },
      });
    },
  },
  created() {
    console.log(this.type);
  },
};
</script>

<style
  scoped
  lang="scss">
.box1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 210px;

  .carousel {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    width: 45%;
  }

  .contents {
    position: relative;
    overflow: auto;
    width: 53%;
    background-color: #fff;
    font-size: 16px;
    > div {
      margin: 4px 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}
.box2 {
  margin-top: 18px;
  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .contents {
      margin-left: 10px;
      background-color: #fff;
      width: 100%;
      padding: 8px 5px;
      cursor: pointer;
      height: 120px;
      line-height: 22px;
      display: flex;
      align-items: center;

      > div {
        max-height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 16px;
      }
    }
    img {
      width: 160px;
      height: 120px;
      object-fit: cover;
    }
  }
}
.box3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 210px;
  .carousel {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    width: 30%;
  }

  .contents {
    width: 69%;
    background-color: #fff;
    font-size: 14px;
    overflow: auto;
    > div {
      // margin:4px 0px;
      height: 40px;
      padding: 0 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
    }
    .title::before {
      content: '●';
      font-size: 10px;
      color: #dd2622;
      margin-right: 10px;
    }
  }
}
</style>
