<template>
  <div
    class="cotainer"
    v-loading="loading">
    <div class="m_b_20">
      <div
        class="box"
        style="position: relative">
        <Headline title="三会一课"></Headline>
        <div
          style="position: absolute; right: 0; top: 10px; cursor: pointer"
          @click="getMore">
          >>更多
        </div>
        <div
          class="introduce"
          v-if="shyk.length > 0">
          <NewList
            :datas="shyk"
            :type="3"
            :pageId="$route.query.id"></NewList>
        </div>
        <el-empty v-else></el-empty>
      </div>
      <div class="box">
        <Headline title="组织生活会"></Headline>
        <div
          class="introduce"
          v-if="zzsh.length > 0">
          <NewList
            :datas="zzsh"
            :type="3"
            :pageId="$route.query.id"></NewList>
        </div>
        <el-empty v-else></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { getContents, getPartyResources } from '@/api/party/index';
import Headline from '../components/Headline';
import NewList from '../components/NewList';
export default {
  name: 'politicalLife',
  data: () => {
    return {
      loading: false,
      parameter: {
        contentIds: '',
        current: 1,
        size: 7,
        isExamine: 1,
      },
      id: '',
      shyk: [],
      zzsh: [],
    };
  },
  components: {
    Headline,
    NewList,
  },
  created() {
    this.loading = true;
    /* 政治生活*/
    getContents({
      id: this.$route.query.id,
    }).then((res) => {
      //三会一课
      this.parameter.contentIds = res.data[0].children[0].id;
      this.id = res.data[0].id;
      getPartyResources(this.parameter).then((res) => {
        this.shyk = res.data.records;
      });
      //组织生活
      this.parameter.contentIds = res.data[0].children[1].id;
      getPartyResources(this.parameter).then((res) => {
        this.zzsh = res.data.records;
      });
      this.loading = false;
    });
  },
  methods: {
    //三会一课详情界面
    getMore() {
      this.$router.push('/party/politicalLife/detail?id=' + this.id);
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
.cotainer {
  padding-bottom: 20px;
  .box {
    margin: 10px 0;
    .introduce {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
